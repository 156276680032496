<template>
  <div>
    <!--Title Bar-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-address-book</v-icon>
        <v-toolbar-title class="pl-2">Contacts</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <time-option />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="timeOptionsStore.timeOptions.data && timeOptionsStore.timeOptions.data.length > 0"
      :showTotal="true"
      :currentPage="timeOptionsStore.timeOptions.current_page"
      :lastPage="timeOptionsStore.timeOptions.last_page"
      :total="timeOptionsStore.timeOptions.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progress !== true">
      <!--List-->
      <list-basic
        :show="timeOptionsStore.timeOptions.data && timeOptionsStore.timeOptions.data.length > 0"
        :list-data="timeOptionsStore.timeOptions.data"
        :title="'Time Options'"
        :section="35"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import ProviderFunctionBar from "@/components/ProviderFunctionBar";

export default {
  name: "ProviderApplications",
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: state => state.providersStore
    })
  },
  components: {
    TitleBar,
    ProviderFunctionBar
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      progress: true,
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("providersStore/read", this.$route.params.providerUUID);
      this.progress = false;
    },

    async filter(page) {},

    async clearFilter() {},

    async openEntry(id) {
      await this.$store.dispatch("contactsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
